import React from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import iphoneImage from "../../assets/panel-iphone-by.webp";
import headphonesImage from "../../assets/headphones-panel-by.webp";
import accessoriesImage from "../../assets/acesories-panel-by.webp";
import macImage from "../../assets/mac-panel-by.webp";
import ipadImage from "../../assets/ipad-panel-mac.webp";
import watchImage from "../../assets/apple-watch-panel-by.webp";

// Стили для карточки с фоновым изображением
const StyledCard = styled(Card)(({ backgroundImage }) => ({
  position: "relative",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: 150,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  color: "#fff",
  backgroundImage: `url(${backgroundImage})`,
  borderRadius: '15px',
  border: '1px solid #e7e7e7',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

const CatalogGrid = () => {
  
  const navigate = useNavigate();
  const cardData = [
    { id: 1, title: "iPhone", category: "iphone", image: iphoneImage },
    { id: 2, title: "Наушники", category: "headphones", image: headphonesImage },
    { id: 3, title: "Аксессуары", category: "accessories", image: accessoriesImage },
    { id: 4, title: "Mac", category: "mac", image: macImage },
    { id: 5, title: "iPad", category: "ipad", image: ipadImage },
    { id: 6, title: "Apple Watch", category: "watch", image: watchImage },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 2, mt:3}}>
      <Grid container spacing={2}>
         {/* Первая строка: одна высокая карточка слева */}
      <Grid item xs={6} md={6} direction="column">
        <StyledCard backgroundImage={cardData[0].image} style={{ height: '100%' }}
        sx={{
              backgroundSize: "160px",
              backgroundPosition: "center 50px",
              backgroundColor: "#f6f6f6",
              backgroundRepeat: "no-repeat",
            }}
        onClick={() => navigate(`/category/${cardData[0].category}`)}
        >
          <CardContent sx={{ padding: 2 }}>
            <Typography variant="h6" component="div" sx={{color:"#000000"}}>
              {cardData[0].title}
            </Typography>
          </CardContent>
        </StyledCard>
      </Grid>

      {/* Правая колонка: две маленькие карточки */}
      <Grid item xs={6} md={6} container spacing={2} direction="column">
        {cardData.slice(1, 3).map((card) => (
          <Grid item key={card.id}>
            <StyledCard backgroundImage={card.image}
            sx={{
              backgroundSize: "180px",
              backgroundPosition: "center 40px",
              backgroundColor: "#f6f6f6",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => navigate(`/category/${card.category}`)}
            >
              <CardContent>
                <Typography variant="h6" component="div"  sx={{color:"#000000"}}>
                  {card.title}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

        {/* Вторая строка: одна карточка на весь ряд */}
        <Grid item xs={12}>
          <StyledCard backgroundImage={cardData[3].image}
            sx={{
              backgroundSize: "250px",
              backgroundPosition: "center 35px",
              backgroundColor: "#f6f6f6",
              backgroundRepeat: "no-repeat",
            }}
          onClick={() => navigate(`/category/${cardData[3].category}`)}
          >
            <CardContent>
              <Typography variant="h6" component="div"  sx={{color:"#000000"}}>
                {cardData[3].title}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Третья строка: две карточки на половину ширины каждая */}

          <Grid item xs={6} md={6}>
            <StyledCard backgroundImage={cardData[4].image}
            sx={{
              backgroundSize: "100px",
              backgroundPosition: "center 40px",
              backgroundColor: "#f6f6f6",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => navigate(`/category/${cardData[4].category}`)}
            >
              <CardContent>
                <Typography variant="h6" component="div"  sx={{color:"#000000"}}>
                  {cardData[4].title}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={6} md={6}>
            <StyledCard backgroundImage={cardData[5].image}
            sx={{
              backgroundSize: "160px",
              backgroundPosition: "center 60px",
              backgroundColor: "#f6f6f6",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => navigate(`/category/${cardData[5].category}`)}
            >
              <CardContent>
                <Typography variant="h6" component="div"  sx={{color:"#000000"}}>
                  {cardData[5].title}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>

      </Grid>
    </Box>
  );
};

export default CatalogGrid;
