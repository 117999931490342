import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import { BottomNavigationAction, Badge } from '@mui/material';
import Paper from '@mui/material/Paper';
import MainreIcon from '../../assets/cat.png';
import ServiceIcon from '../../assets/gar.png';
import PromoIcon from '../../assets/dev.png';
import ProfileIcon from '../../assets/cart.png';
import './Menu.css';

const Menu = () => {
    const location = useLocation();
    const navigate = useNavigate(); // 🔥 useNavigate() вместо Link
    const [value, setValue] = React.useState(0);
    const cartItemCount = 3;
    const routes = ['/', '/service', '/promotions', '/profile'];

    useEffect(() => {
        const currentIndex = routes.indexOf(location.pathname);
        if (currentIndex !== -1) {
            setValue(currentIndex);
        }
    }, [location.pathname]);

    const handleNavClick = (event, newValue) => {
        setValue(newValue);
        navigate(routes[newValue]); // 🔥 Используем useNavigate()
    };

    return (
        <Paper sx={{ position: 'fixed', bottom: 15, left: 0, right: 0, marginLeft: 2, marginRight: 2, zIndex: 1000 }} elevation={10}>
            <BottomNavigation
                value={value}
                sx={{ backgroundColor: '#f6f6f6' }}
                onChange={handleNavClick} // 🔥 Теперь работает через useNavigate()
                showLabels
            >
                <BottomNavigationAction label="Каталог" icon={<img src={MainreIcon} alt="Каталог" style={{ width: 25, height: 25 }}/>} />
                <BottomNavigationAction label="Акции" icon={<img src={ServiceIcon} alt="Акции" style={{ width: 25, height: 25 }}/>} />
                <BottomNavigationAction label="Помощь" icon={<img src={PromoIcon} alt="Помощь" style={{ width: 25, height: 25 }}/>} />
                <BottomNavigationAction
                    label="Корзина"
                    icon={
                        <Badge badgeContent={cartItemCount} color="error">
                            <img src={ProfileIcon} alt="Корзина" style={{ width: 25, height: 25 }} />
                        </Badge>
                    }
                />
            </BottomNavigation>
        </Paper>
    );
};

export default Menu;
