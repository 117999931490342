import React, { useState } from 'react';
import { Box, Typography, Avatar, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import wp from '../../assets/wp.svg';
import tg from '../../assets/tg.svg';
import vb from '../../assets/vb.svg';
import call from '../../assets/call.svg';
import sms from '../../assets/sms.svg';
import mng from '../../assets/mng.png';

const ContactInfo = ({ icon, text }) => (
  <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary', display: 'flex' }}>
    <img src={icon} alt="Logo" style={{ width: '16px', height: 'auto' }} />
    <Box sx={{ pl: 1 }}>{text}</Box>
  </Typography>
);

const ProfilePage = () => {
  const userId = useSelector(state => state.userId);

  // Адрес для отображения
  const address = " Cumhuriyet Mah. Sanat Okulu Cd No:1 Alanya Merkez/Antalya, Türkiye";

  // Координаты для карты (широта и долгота)
  const latitude = 36.5398243; // Пример широты
  const longitude = 32.0268625; // Пример долготы

  // Состояние для отслеживания загрузки карты
  const [isMapLoading, setMapLoading] = useState(true);

  return (
    <Box>

      
    </Box>
  );
};

export default ProfilePage;
