import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

// Стили для карточки с фоновым изображением
const StyledCard = styled(Card)(({ backgroundImage }) => ({
  position: "relative",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  color: "#fff",
  backgroundImage: `url(${backgroundImage})`,
  borderRadius: "10px",
  border: "1px solid #e7e7e7",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}));

const CategoryPage = () => {
  const { categoryName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const backButton = window.Telegram.WebApp.BackButton;

    backButton.show();

    const handleBackClick = () => {
      navigate("/"); // Возврат на главную или категорию
    };

    backButton.onClick(handleBackClick);

    return () => {
      backButton.offClick(handleBackClick); // Удаление того же обработчика
      backButton.hide();
    };
  }, [navigate]);

  // Заглушка товаров (можно заменить на API-запрос)
  const products = [
    {
      id: 1,
      name: "Apple iPhone 16 Pro",
      img: "https://content2.rozetka.com.ua/goods/images/big_tile/468886414.jpg",
      price: "49000",
      newPrice: "42500",
      category: categoryName,
    },
    {
      id: 2,
      name: "Apple iPhone 16",
      img: "https://content2.rozetka.com.ua/goods/images/big_tile/468886490.jpg",
      price: "49000",
      newPrice: "",
      category: categoryName,
    },
    {
      id: 3,
      name: "Apple iPhone 16 Pro Max",
      img: "https://content1.rozetka.com.ua/goods/images/big_tile/468887116.jpg",
      price: "49000",
      newPrice: "42500",
      category: categoryName,
    },
    {
      id: 4,
      name: "Apple iPhone 16 Plus",
      img: "https://content1.rozetka.com.ua/goods/images/big_tile/468888934.jpg",
      price: "49000",
      newPrice: "",
      category: categoryName,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 2, mt: 3 }}>
      <Grid container spacing={1} alignItems="stretch">
        {products.map((product) => (
          <Grid item xs={6} md={6} key={product.id}>
            <StyledCard
              sx={{
                backgroundColor: "#ffffff",
                height: "100%", // Карточка занимает всю высоту
              }}
              onClick={() =>
                navigate(`/category/${categoryName}/product/${product.id}`)
              }
              style={{ cursor: "pointer" }}
            >
              {/* Изображение товара */}
              <Box
                component="img"
                src={product.img} // Замените на URL изображения
                alt={product.name}
                sx={{
                  width: "100%",
                  objectFit: "cover", // Обеспечиваем корректное масштабирование
                  padding: "8px",
                }}
              />
              {/* Контент под изображением */}
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                  padding: 1,
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  {product.name}
                </Typography>

                {/* Логика отображения цены */}
                {product.newPrice ? (
                  <Box sx={{ mt: 1, textAlign: "left" }}>
                    <Typography
                      sx={{
                        textDecoration: "line-through",
                        color: "#999999",
                        fontSize: "13px",
                      }}
                    >
                      {product.price} ₽
                    </Typography>
                    <Typography
                      sx={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {product.newPrice} ₽
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: "16px",
                      mt: 1,
                    }}
                  >
                    {product.price} ₽
                  </Typography>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CategoryPage;
