import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Menu from "./Components/Menu/Menu";
import CatalogPage from './Pages/CatalogPage/CatalogPage';
import ServicePage from './Pages/ServicePage/ServicePage';
import PromoPage from './Pages/PromoPage/PromoPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import CategoryPage from './Pages/CategoryPage/CategoryPage';
import ProductPage from "./Pages/ProductPage/ProductPage"; // Карточка товара
import HeaderBlock from './Components/Header/HeaderBlock';
import './App.css';
import StartImg from './assets/logo.svg';

function App() {
  const dispatch = useDispatch();
  const [telegramLoaded, setTelegramLoaded] = useState(false);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;
      if (tg.platform === "android") {
        tg.requestFullscreen();
        tg.disableVerticalSwipes();
      } else if (tg.platform === "ios") {
        tg.requestFullscreen();
        tg.disableVerticalSwipes();
      } else if (tg.platform === "tdesktop") {
      } else {
        console.log("Приложение открыто в веб-браузере");
      }
      tg.expand();

      const userId = tg.initDataUnsafe.user?.id || null;
      const userName = tg.initDataUnsafe.user?.username || null;
      const userPlatform = tg.platform || null;

      dispatch({ type: 'SET_USER_DATA', payload: { userId, userName, userPlatform } });

      setTimeout(() => {
        setTelegramLoaded(true);
      }, 3000);
      tg.ready();
    }
  }, [dispatch]);

  if (!telegramLoaded) {
    return (
      <div className="loading-screen">
        <img src={StartImg} alt="Loading..." />
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <HeaderBlock />
        <Routes>
          <Route path="/" element={<CatalogPage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/promotions" element={<PromoPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/category/:categoryName" element={<CategoryPage />} />
          <Route path="/category/:categoryName/product/:id" element={<ProductPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Menu />
      </div>
    </Router>
  );
}

export default App;
