import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ProductPage = () => {
  const { id, categoryName } = useParams();  // Получаем и id, и categoryName из параметров маршрута
  const navigate = useNavigate();

  useEffect(() => {
    const backButton = window.Telegram.WebApp.BackButton;

    // Показываем кнопку "Назад"
    backButton.show();

    // Обработчик кнопки "Назад"
    const handleBackClick = () => {
      navigate(`/category/${categoryName}`); // Возврат к категории
    };

    backButton.onClick(handleBackClick);

    // Очистка обработчиков при размонтировании
    return () => {
      backButton.offClick(handleBackClick);
      backButton.hide();
    };
  }, [navigate, categoryName]);

  return (
    <div>
      <h1>Товар #{id}</h1>
      <p>Описание товара...</p>
    </div>
  );
};

export default ProductPage;
